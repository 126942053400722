import React from "react"
import { Link } from "gatsby"

const About = () => {
  return (
    <div className="container">
      <div className="article">
        <p>
          <span className="highlight">
            <Link to="/" exact="true">
              Shoya, Inc.
            </Link>
          </span>{" "}
          (hereby &#34;the Corporation&#34;) is a media conglomerate nearing a
          $7T market valuation.
        </p>
        <p>
          The Corporation&#39;s principal holdings are{" "}
          <span className="highlight">
            <Link to="/light-camera" exact="true">
              Shoya Light & Camera
            </Link>
          </span>
          , <br /> a major motion picture studio that has produced countless
          award-winning films, and{" "}
          <span className="highlight">
            <Link to="/shoyaright" exact="true">
              The Shoyaright!
            </Link>
          </span>
          , a widely respected publication for extremely sophisticated cultural
          criticism.{" "}
        </p>
        <p>
          The Corporation is absolutely not, no matter what anyone says, a
          one-person operation run by{" "}
          <a
            href="https://twitter.com/zuffshoya"
            target="__blank"
            rel="noopener noreferrer"
          >
            Zuff Shoya
          </a>{" "}
          (aka Lil Shoyaright!) with occasional help from some of his friends.
        </p>
        <p>
          We assure you, we have a robust population of employees whom we
          routinely exploit for labor and refuse basic health coverage.
        </p>
        <p>
          For inquiries, please contact the Corporation&#39;s very important
          CEO, who is &#34;only an asshole when he needs to be&#34;, directly:{" "}
          <span className="highlight">
            <a href="mailto:zuff@shoya.co">zuff@shoya.co</a>
          </span>
        </p>
      </div>
      <div className="copyright">
        <p>&copy; Shoya, Inc. 2020-</p>
      </div>
    </div>
  )
}

export default About
